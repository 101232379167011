import React from 'react';

import Layout from '../components/body/layout';
import Head from '../components/header/head';

import mainText from './index.module.scss';



const Contactpage = () => {
    return (
        <Layout>
            <Head title="Contact" />
            <div className={mainText.text}>
                <h2>Contact</h2>
                <h3>Tumba Design</h3>
                <p>mail: info@tumba.be</p>
                <p>fb: <a href="http://www.facebook.com/tumba-design" target="_blank" rel="noopener noreferrer">Facebook</a></p>
                <p>instagram: <a href="http://www.instagram.com/tumba-design" target="_blank" rel="noopener noreferrer">Instagram</a></p>
                <p>Twitter: <a href="http://www.twitter.com/tumba-design" target="_blank" rel="noopener noreferrer">J.Jansen</a></p>
            </div>
        </Layout>
    )
}

export default Contactpage